@import "variable.scss";
/* for font face */
@mixin font-face($style-name, $file, $family, $category:"") {
    $filepath: "fonts/" + $family + "/" + $file;
    @font-face {
        font-family: "#{$style-name}";
        src: url($filepath + ".eot");
        src: url($filepath + ".eot?#iefix") format('embedded-opentype'), url($filepath + ".woff") format('woff'), url($filepath + ".ttf")  format('truetype'), url($filepath + ".svg#" + $style-name + "") format('svg');
    }
    %#{$style-name} {
        font: {
            @if $category != "" {
                family: "#{$style-name}", #{$category};
            }
            @else {
                family: "#{$style-name}";
                weight: normal;
            }
        }
    }
}


@mixin  makeCustom-container() {
    width: 100%;
    padding-left: $default-gutter;
    padding-right: $default-gutter;
    margin-left: auto;
    margin-right: auto;

}

@mixin make-customContainer-max-width ($maxWidth-map: $container_MaxWidth) {
    @each $screen-width, $container-max-width in $maxWidth-map {
        @include breakpoint-min(calc-rem($screen-width)) {
            max-width: $container-max-width;
        }
    }
}

@mixin pdpImg-container () {
    width: 100%;
    padding-top: 20px;
}

@mixin pdpImg-container-max-width ($maxWidth-map: $pdpImg-MaxWidth) {
    @each $screen-width, $max-width in $maxWidth-map {
        @include breakpoint-min(calc-rem($screen-width)) {
            width: $max-width;
        }
    }
}


/* rem calculator */
@function calc-rem($target) {
    @return ($target / $text__size--default) * 1rem;
}
@function calc-em($target, $context: $text__size--default) {
    @return ($target / $context) * 1em;
}
/*====mixin for media query=====*/
@mixin breakpoint-min($point){
   @media only screen and (min-width: $point) { @content ; }
    /*@media only screen (min-width:$point){@content;}*/
}
@mixin breakpoint-max($point){
    @media only screen and (max-width:$point){@content;}
}

@mixin breakpoint-range($point1,$point2){
    @media only screen and (min-width:$point1) and (max-width:$point2){@content;}
}

@function calcPadding($width,$height){
    @return ($height/$width)*100%;
}


@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }

    &:-moz-placeholder { /* Firefox 18- */
        @content;
    }

    &::-moz-placeholder {  /* Firefox 19+ */
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }
}


/* pdp mixin */
@mixin prodInfoSpans($pcolor:true) {
    display: flex;
    margin-bottom: 15px;
    @if $pcolor {
        color: $primaryColor;
    } @else {
        color: $secondryColor;
    }
}