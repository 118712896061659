.footer {
  background: #0d1a2c;
  padding: 50px 0;
  .text-box {
    display: flex;
    flex-wrap: wrap;
  }
  .footer-logo {
    width: 135px;
    margin-right: 30px;
  }
  .footer-text {
    flex: 1;
    .title {
      font-family: CircularStd-Bold;
      font-size: calc-rem(50);
      color: #ffffff;
      margin: 10px 0 20px 0;
      line-height: normal;
    }
   
  }
  .text {
    font-family: CircularStd-Book;
    font-size: calc-rem(20);
    color: rgba(255, 255, 255, 0.6);
    line-height: normal;
    flex: 0 0 100%;
    display: none;
    @include breakpoint-min(calc-rem(992)) {
      display: block;
    }
  }
  .mobile-text{
    font-family: CircularStd-Book;
    font-size: calc-rem(16);
    color: rgba(255, 255, 255, 0.6);
    line-height: normal;
    flex: 0 0 100%;
    display: block;
    margin-top:10px;
    @include breakpoint-min(calc-rem(992)) {
      display: none;
    }
  }
  @include breakpoint-min(calc-rem(320)) {
    .footer-logo {
      width: 50px;
      margin-right: 25px;
    }
    .footer-text {
      .title {
        font-size: calc-rem(30);
      }
    }
  }
  @include breakpoint-min(calc-rem(768)) {
    .footer-logo {
      width: 50px;
      margin-right: 30px;
    }
    .footer-text {
      .title {
        font-size: calc-rem(30);
        margin: 10px 0 15px 0;
      }
    }
  }
  @include breakpoint-min(calc-rem(992)) {
    .footer-logo {
      width: 75px;
      margin-right: 30px;
    }
    .footer-text {
      .title {
        font-size: calc-rem(40);
        margin: 17px 0 20px 0;
      }
    }
  }
  @include breakpoint-min(calc-rem(1280)) {
    .footer-logo {
      width: 135px;
      margin-right: 30px;
    }
    .footer-text {
      .title {
        font-size: calc-rem(50);
        margin: 30px 0 20px 0;
      }
    }
  }
}

.radio-box {
  color: #fff;
}
.radio-grid {
  display: flex;
  margin-bottom: 35px;
  flex: 0 0 100%;
  width: 100%;
  .radio-box {
    position: relative;
    margin: 0 20px 0 0;
    span {
      padding: 0 0 0 25px;
    }
    input[type="radio"] {
      opacity: 0;
      position: absolute;
      width: 100%;
      top: 4px;
      height: 100%;
      z-index: 1;
    }
    input[type="radio"] + span:after {
      content: "";
      position: absolute;
      top: 2px;
      left: 0;
      height: 19px;
      width: 19px;
      border: 2px solid #fff;
      border-radius: 15px;
    }

    input[type="radio"] + span:before {
      content: "";
      width: 9px;
      height: 9px;
      background: #0f6cf3;
      position: absolute;
      top: 7px;
      left: 5px;
      border-radius: 100%;
      transition: all 0.2s ease;
    }
    input[type="radio"]:not(:checked) + span:before {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    input[type="radio"]:checked + span:before {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}
.subscribe-box {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin: 20px 0 0 0;
  input {
    background: #21344d;
    border: none;
    border-radius: 5px 0 0 5px;
    padding: 13px 20px;
    font-family: CircularStd-Book;
    color: rgba(255, 255, 255, 0.4);
    width: 263px;
  }
  button {
    font-family: CircularStd-Medium;
    font-size: calc-rem(16);
    color: #ffffff;
    background: #0f6cf3;
    border-radius: 0 5px 5px 0;
    padding: 13px 20px;
    border: none;
  }
  .msg-area{
      font-family: CircularStd-Book;
      // color: rgba(255, 255, 255, 0.6);
      line-height: normal;
      font-size:calc-rem(14);
      margin-top:10px;
  }
  .error{
    color: red;
  }
  .success{
    color:green;
  }
  @include breakpoint-min(calc-rem(320)) {
    .subscribe-content {
      width: 100%;
      margin-top: 10px;
    }
    input {
      width: 100%;
    }
  }
  @include breakpoint-min(calc-rem(768)) {
    margin: 20px 0 0 0;
    .subscribe-content {
      width: auto;
      margin-top: 0;
    }
    input {
      width: 240px;
    }
  }
  @include breakpoint-min(calc-rem(992)) {
    margin: 37px 0 0 0;
    input {
      width: 263px;
    }
  }
  @include breakpoint-min(calc-rem(1280)) {
    margin: 50px 0 0 0;
  }
}
.submit-box {
  display: flex;
}
.layer {
  padding-bottom: 30px;
  margin-bottom: 50px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  &:last-child {
    padding: 0;
    margin: 0;
    border: none;
  }
}
// .nav-list{
//     display: flex;
.nav-box {
  .list-title {
    font-family: CircularStd-Bold;
    font-size: calc-rem(14);
    color: #ffffff;
    text-transform: uppercase;
    margin: 0 0 30px 0;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      display: block;
      margin-bottom: 15px;
      a {
        opacity: 0.5;
        font-family: CircularStd-Book;
        font-size: calc-rem(14);
        color: #ffffff;
        display: inline-block;
        text-decoration: none;
      }
    }
  }
  @include breakpoint-min(calc-rem(320)) {
    margin: 0 0 0px 0;
    ul {
      li {
        margin-bottom: 10px;
      }
    }
  }
  @include breakpoint-min(calc-rem(768)) {
    margin: 0;
    ul {
      li {
        margin-bottom: 15px;
      }
    }
  }
}
//}
.btn-box {
  display: flex;
  justify-content: flex-end;
  a {
    font-family: CircularStd-Medium;
    font-size: calc-rem(15);
    color: #ffffff;
    display: inline-block;
    padding: 10px 20px;
    border: 1px solid #fff;
    border-radius: 5px;
  }
  @include breakpoint-min(calc-rem(320)) {
    align-items: flex-end;
    height: 100%;
    justify-content: flex-start;
    a {
      margin-bottom: 10px;
    }
  }
  @include breakpoint-min(calc-rem(768)) {
    align-items: flex-start;
    justify-content: flex-end;
  }
}
.copy-right {
  
  font-family: CircularStd-Book;
  font-size: calc-rem(12);
  color:rgba(255,255,255,.5);
  margin: 0;
  text-align: center;
  @include breakpoint-min(calc-rem(576)) {
    text-align: left;
  }
  a{
    color: #fff;
    text-decoration: none;
  }
}
.right-align {
  display: flex;
  justify-content: center;
  @include breakpoint-min(calc-rem(576)) {
    justify-content: flex-end;
  }
}
.help-mail {
  font-family: CircularStd-Book;
  font-size: calc-rem(12);
  color: #ffffff;
  margin: 0;
  a{
    text-decoration:none;
    color:#fff;
  }
}
.chat-box {
  opacity: .5;
  margin: 0;
  line-height: normal;
  display: flex;
  margin-top:10px;
  @include breakpoint-min(calc-rem(576)) {
    margin-top:0px;
  }
}
.chat-box a{
  font-size:10px; 
  display: inline-block;
  color: #fff;
}
.chat-box img{
  width:15px;
  margin-right:5px; 
  }