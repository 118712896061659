.main-header {
  position: fixed;
  width: 100%;
  padding: 20px 0;
  top: 0px;
  z-index: 9;
  transition: all 0.5s;
  
  @include breakpoint-min(calc-rem(768)) {
    padding: 35px 0;
  }
}
.fix-header {
  .main-header {
    background: #fff;
    top: 0;
    padding: 20px 0;
    box-shadow: 0 -7px 15px #8a8a8a;
    //padding: 10px 0;
    transition: all 0.5s;
   
  }
  .box-link {
    // border: 1px solid #000 !important;
  }
 
}
.menu-box {
  display: flex;
  justify-content: flex-end;
  height: 100%;
}
.menu-list {
  @include breakpoint-min(calc-rem(320)) {
    display: none;
    position: fixed;
    height: 100%;
    width: 100%;
    background: #fff;
    top: 0;
    left: 0;
  }
  @include breakpoint-min(calc-rem(992)) {
    display: block !important;
    position: relative;
    background: none;
  }
  //  display: block;
}
.logo {
  position: relative;
  margin-top: 0px;
  a {
    display: inline-block;
  }
  img {
    width: 160px;
  }
  @include breakpoint-min(calc-rem(992)) {
    margin-top: 6px;
  }
}
.mobile-box{
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100% - 71px);
  flex-wrap: wrap;
  align-items: center;
  @include breakpoint-min(calc-rem(992)){
    display: block;
    top: 0px;
    left: 0;
    overflow: auto;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
  }
}
.nav-bar {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  li {
    margin-left: 25px;
  }
  a {
    text-decoration: none;
    display: inline-block;
    color: #000;
    font-family: CircularStd-Book;
    font-size: calc-rem(16);
    padding:10px 5px;
    transition: .5s;
    &:hover{
        color: #0F6CF3;
    }
    &.active {
      color: #0F6CF3;
    }
    
  }
  .box-link {
    padding:7px 5px;
    transition: 0.5s;
    &:hover{
        background: #0F6CF3;
        color:#fff;
        border:1px solid #0F6CF3;
    }
    
    @include breakpoint-min(calc-rem(992)) {border: 1px solid #262626;
      border-radius: 5px;padding:10px 30px;}
  }
  @include breakpoint-min(calc-rem(320)) {
    display: block;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    
    a {
      font-size: calc-rem(35);
    }
  }
  @include breakpoint-min(calc-rem(992)) {
    position: relative;
    top: auto;
    display: flex;
    transform: translate(0%, 0%);
    left: 0;
    
    a {
      font-size: calc-rem(15);
    }
  }
  @include breakpoint-min(calc-rem(1024)) {
    li{
      margin-left: 30px;
    }
  }
}
.navbar-box {
  padding: 0;
}
.burger-btn {
  display: none;
  background: none;
  border: none;
  position: absolute;
  top:4px;
  right: 0;
  padding: 0;
  @include breakpoint-min(calc-rem(320)) {
    display: flex;
    height: 24px;
    width: 24px;
  }
  @include breakpoint-min(calc-rem(992)) {
    display: none;
  }
  span {
    height: 2px;
    background: #000;
    border-radius: 5px;
    position: relative;
    &::after {
      content: "";
      height: 2px;
      background: #000;
      border-radius: 5px;
      position: absolute;
      top: 7px;
      width: 100%;
      left: 0;
    }
    &::before {
      content: "";
      height: 2px;
      background: #000;
      border-radius: 5px;
      position: absolute;
      bottom: 7px;
      width: 100%;
      left: 0;
    }
  }
}

.menu-header {
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  align-items: center;
  img{
    width:160px;
  }
  .close-menu {
    width: 24px;
    height: 24px;
    background:none;
    border:none;
    padding: 0;
    span {
      position: relative;
      display: block;
      height: 100%;
      width: 100%;
      &::after {
        content: "";
        height: 2px;
        background: #000;
        border-radius: 5px;
        position: absolute;
        top: 11px;
        width: 24px;
        left: 0px;
        transform: rotate(-45deg);
      }
      &::before {
        content: "";
        height: 2px;
        background: #000;
        border-radius: 5px;
        position: absolute;
        top: 11px;
        width: 24px;
        left: 0px;
        transform: rotate(45deg);
      }
    }
  }

  @include breakpoint-min(calc-rem(992)) {
    display: none;
  }
}

@include breakpoint-min(calc-rem(768)) {
  .menu-header{
    padding:35px 30px;
  }
  .fix-header{
    .menu-header{
      padding:20px 30px;
    }
  }
}
