.portfolio-box {
  position: relative;
  max-height: 533px;
  width: 100%;

  @mixin height_width {
    // height: 177px;
    width: 25%;
  }
  .portfolio {
    position: absolute;
    opacity: 0;
    transform: translate(0px, 130px);
    transition: all 0.6s ease-out;
  }
  .portfolio.visible {
    opacity: 1;
    transform: none;
  }
  .portfolio:nth-child(1) {
    top: 0%;
    left: 0%;
    width: 50%;
    z-index: 1;
  }
  .portfolio:nth-child(2) {
    top: 20%;
    left: 43%;
    @include height_width();
    transition-delay: 0.3s;
  }
  .portfolio:nth-child(3) {
    top: 32%;
    right: 0;
    @include height_width();
    transition-delay: 0.5s;
  }
  .portfolio:nth-child(4) {
    top: 59%;
    left: 43%;
    @include height_width();
    transition-delay: 0.7s;
  }
  .portfolio:nth-child(5) {
    bottom: 0%;
    right: 0;
    @include height_width();
    transition-delay: 0.9s;
  }
  @include breakpoint-min(calc-rem(768)) {
    height: 310px;
  }
  @include breakpoint-min(calc-rem(992)) {
    height: 395px;
  }
  @include breakpoint-min(calc-rem(1024)) {
    height: 415px;
  }
  @include breakpoint-min(calc-rem(1200)) {
    height: 533px;
    .portfolio:nth-child(2) {
      top: 19%;
      left: 43%;
      @include height_width();
      transition-delay: 0.3s;
    }
    .portfolio:nth-child(3) {
      top: 30%;
      right: 0;
      @include height_width();
      transition-delay: 0.5s;
    }
    .portfolio:nth-child(4) {
      top: 57%;
      left: 43%;
      @include height_width();
      transition-delay: 0.7s;
    }
    .portfolio:nth-child(5) {
      bottom: 0%;
      right: 0;
      @include height_width();
      transition-delay: 0.9s;
    }
  }
}
.invite-box {
  position: relative;
  width: 90%;
  text-align: left;
  .invite {
    display: inline-block;
    opacity: 0;
    transform: translate(110px, 0px);
    transition: all 0.6s ease-out;
    position: relative;
    z-index: 1;
  }
  .invite.visible {
    opacity: 1;
    transform: none;
  }
  @mixin invite_box {
    width: 55%;
    position: relative;
  }
  .invite:nth-child(1) {
    width: 64%;
  }
  .invite:nth-child(2) {
    @include invite_box();
    left: 27%;
    margin-top: 10%;
  }
  .invite:nth-child(3) {
    @include invite_box();
    left: 45%;
    margin-top: 5%;
  }

  .invite-bg {
    position: absolute;
    width: 64%;
    left: 50%;
    transform: translate(-50%, 45%);
    z-index: 0;
    opacity: 0;
    transition: all 0.6s ease-out;
  }
  .invite-bg.visible {
    opacity: 1;
    transform: translate(-50%, 0%);
  }
  .invite-bg:nth-last-child(2) {
    top: 10%;
  }
  .invite-bg:nth-last-child(1) {
    top: 65%;
  }
}
.progress-box {
  position: relative;
  width: 100%;
  text-align: left;
  .progress-bx {
    opacity: 0;
    transform: translate(-50%, 0%);
    transition: all 0.6s ease-out;
    position: absolute;
    z-index: 1;
    vertical-align: top;
    z-index: 3;
    img {
      width: 50%;
    }
  }
  .progress-bx.visible {
    opacity: 1;
    transform: none;
  }
  @mixin progress_box {
    width: 55%;
    // max-width: 177px;
    // position: relative;
  }
  .progress-bx:nth-child(1) {
    @include progress_box();
    top: 10%;
  }
  .progress-bx:nth-child(2) {
    @include progress_box();
    top: 32%;
    left: 21%;
  }
  .progress-bx:nth-child(3) {
    @include progress_box();
    right: -31%;
    top: 50%;
    transition-delay: 0.8s;
  }
  .progress-bx:nth-child(4) {
    @include progress_box();
    right: -18%;
    bottom: 2%;
    transition-delay: 0.5s;
  }
  .progress-bg {
    position: relative;

    transform: translate(0%, 25%);
    z-index: 0;
    opacity: 0;
    transition: all 0.6s ease-out;
  }
  .progress-bg.visible {
    opacity: 1;
    transform: none;
  }
  .progress-bg:nth-last-child(2) {
    text-align: right;
    z-index: 1;
    img {
      width: 60%;
    }
  }
  .progress-bg:nth-last-child(1) {
    margin-top: -8%;
    // max-width:374px;
    opacity: 1;
    z-index: 0;
  }
  .progress-bg:nth-last-child(1) {
    img {
      transform: scale(1, 0.7);
      transition: all 0.5s ease;
      width: 71%;
    }
  }
  .progress-bg.visible:nth-last-child(1) {
    img {
      transform: scale(1, 1);
    }
  }
}

.influencer-boxes {
  position: relative;
  height: 500px;
  width: 300px;
  margin: 30px auto 0;
  .influencer {
    position: absolute;
    width: 48%;
    opacity: 0;
    transform: translate(0px, 150px);
    transition: all 0.6s ease-out;
    &:nth-child(1) {
      top: 0%;
      left: 9%;
      z-index: 1;
    }
    &:nth-child(2) {
      top: 9%;
      z-index: 0;
    }
    &:nth-child(3) {
      right: 10%;
      top: 22%;
    }
    &:nth-child(4) {
      left: 20%;
      bottom: 8%;
      
    }
    &:nth-child(5) {
      right: 0;
      bottom: 0;
    }
  }
  .influencer.visible {
    opacity: 1;
    transform: none;
  }
  @include breakpoint-min(calc-rem(768)) {
    height: 572px;
    width: 100%;
  }
  @include breakpoint-min(calc-rem(992)) {
    height: 632px;
    width: 80%;
  }
  @include breakpoint-min(calc-rem(1024)) {
    height: 672px;
    width: 80%;
  }
  @include breakpoint-min(calc-rem(1200)) {
    height: 839px;
  width: 496px;
  }
}
.influencers-box{
.influencer-profile{
  opacity: 1;
  transform: scale(0.1) ;
  transition: all 0.4s cubic-bezier(0,1,0,0);
  animation: moveit2 400ms ease forwards;
  
  img{
    transform: scale(0);
    opacity: 0;
    transition: .4s;
    
  }
}
.influencer-profile.visible {
  opacity: 1;
  transform: scale(1) ;
  animation: moveit 900ms ease forwards;
  background: transparent;
  img{
    transform: scale(1);
    opacity: 1;
  }
}
}
@keyframes moveit {
  0% { transform:  scale3d(0.96,0.96,1); }
  20% { transform:  scale3d(1.1,1.1,1); }
  40% { transform:  scale3d(0.98,0.98,1); }
  60% { transform:  scale3d(1.05,1.05,1); }
  80% { transform:  scale3d(1.01,1.01,1); }
  100% { transform:  scale3d(1,1,1); }
}
@keyframes moveit2 {
  0% { transform:  scale3d(0.96,0.96,1); }
  
  0% { transform:  scale3d(0.96,0.96,1); }
}
