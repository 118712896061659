.text-content {
  .text-box {
    margin-bottom: 50px;
  }
  .title {
    font-size: calc-rem(20);
    color: #2362be;
    line-height: 30px;
    margin-bottom: 20px;
  }
  .para_text {
    font-size: calc-rem(16);
    line-height: 25px;
    text-align: justify;
    font-family: Avenir-Book;
  }
  @include breakpoint-min(calc-rem(992)) {
    .text-box {
        margin-bottom: 90px;
      }
      .title {
        font-size: calc-rem(30);
        color: #2362be;
        line-height: 46px;
        margin-bottom: 30px;
      }
       
  }
}
