.pricing-box {
    max-width: 910px;
    margin: 0 auto 80px;
    padding: 0 30px;
    
    @include breakpoint-min(calc-rem(992)) {
    padding: 0 10px;
    }
    .head-section {
        margin-bottom: 60px;
        .title {
            font-family: CircularStd-Medium;
            font-size: calc-rem(25);
            letter-spacing: 0;
            text-align: center;
            color: #2c2c2c;
            margin-bottom: 10px;
            .active {
                color: #0f6cf3;
                display: block;
            }
        }
        .note {
            font-family: CircularStd-Book;
            font-size: calc-rem(14);
            color: #878787;
            letter-spacing: 0;
            text-align: center;
        }
        @include breakpoint-min(calc-rem(768)) {
            .title {
                font-size: calc-rem(40);
            }
            .note {
                font-size: calc-rem(17);
            }
        }
        @include breakpoint-min(calc-rem(992)) {
            .title {
                font-size: calc-rem(50);
            }
        }
    }

    .plan-box {
        
        @include breakpoint-min(calc-rem(992)) {padding: 0 25px;}
        @include breakpoint-min(calc-rem(1024)) {
            padding: 0;
        }
        .grid-item {
            padding: 0 19px;
            @include breakpoint-min(calc-rem(992)) {
                
                &:nth-child(1) {
                    padding-right: 15px;
                }
                &:nth-child(2) {
                    padding-left: 15px;
                }
            }
            @include breakpoint-min(calc-rem(1024)) {
                padding: 0 25px;
                &:nth-child(1) {
                    padding-right: 25px;
                }
                &:nth-child(2) {
                    padding-left: 25px;
                }
            }
        }
        .plan-item {
            border-radius: 10px;
            padding: 20px;
            height: auto;
            background: #0f6cf3;
            color: #fff;
            position: relative;
            margin-bottom: 30px;
            &.inactive {
                background: #e8eff8;
                color: #2c2c2c;
                .title-section {
                    .tag {
                        background: rgba(87, 87, 87, 0.25);
                    }
                }
                .price-section {
                    .price-tags {
                        .amount-tag {
                            color: #2c2c2c;
                            &.discount {
                                opacity: 0.5;
                            }
                        }
                    }
                    .note {
                        color: #2c2c2c;
                    }
                }
                .btn-section {
                    .btn {
                        color: rgba(44, 44, 44, 0.4);
                        border: 1px solid rgba(44, 44, 44, 0.4);
                        background: none;
                        cursor: default;
                    }
                    .note {
                        font-family: CircularStd-Book;
                        font-size: calc-rem(12);
                        color: rgba(44, 44, 44, 0.4);
                    }
                }
                .feature-section {
                    .listing {
                        li {
                            .mark {
                                background-position: -17px 0;
                            }
                        }
                    }
                }
                .message-section {
                    p {
                        color: rgba(44, 44, 44, 0.4);
                    }
                }
            }
            &.pro {
                &.inactive {
                    .title-section {
                        .name-box {
                            .icon {
                                background-position: 0px -40px;
                            }
                        }
                    }
                }
            }
            &.basic {
                .title-section {
                    .name-box {
                        .icon {
                            background-position: -40px 0px;
                        }
                    }
                }
                &.inactive {
                    .title-section {
                        .name-box {
                            .icon {
                                background-position: -40px -40px;
                            }
                        }
                    }
                }
            }

            .title-section {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 40px;
                .name-box {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    font-family: CircularStd-Bold;
                    font-size: calc-rem(30);line-height:normal;
                    .icon {
                        background: url("../..//images/plan-icon.svg") no-repeat;
                        height: 40px;
                        width: 40px;
                        display: inline-block;
                        margin-right: 10px;
                    }
                }
                .tag {
                    padding: 6px 10px;
                    font-size: calc-rem(14);
                    border-radius: 4px;
                    background: rgba(255, 255, 255, 0.3);
                }
            }

            .price-section {
                margin-bottom: 25px;
                .price-tags {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .amount-tag {
                        font-family: CircularStd-Bold;
                        font-size: calc-rem(25);
                        &.discount {
                            opacity: 0.5;
                            text-decoration: line-through;
                        }
                        .rupee {
                            font-size: calc-rem(24);
                        }
                        .sm-text {
                            font-family: CircularStd-Medium;
                            font-size: calc-rem(18);
                        }
                    }
                    @include breakpoint-min(calc-rem(768)) {
                        .amount-tag {
                            font-size: calc-rem(37);
                            .rupee {
                                font-size: calc-rem(38);
                            }
                        }
                    }
                }
                .note {
                    font-size: calc-rem(13);
                    margin-bottom: 0;
                    color: #c0daff;
                }
            }
            .btn-section {
                margin-bottom: 40px;
                .btn {
                    font-family: CircularStd-Medium;
                    font-size: calc-rem(15);
                    background: #fff;
                    text-align: center;
                    width: 100%;
                    padding: 13px;
                    border-radius: 5px;
                    margin-bottom: 10px;
                    border: 1px solid #fff;
                    color: #0f6cf3;
                }
                .note {
                    font-family: CircularStd-Book;
                    font-size: calc-rem(12);
                    color: rgba(255, 255, 255, 0.4);
                }
            }
            .feature-section {
                margin-bottom: 50px;
                .listing {
                    list-style: none;
                    padding: 0;
                    li {
                        font-family: CircularStd-Book;
                        font-size: calc-rem(14);
                        margin-bottom: 20px;
                        display: flex;
                        align-items: flex-start;
                        .mark {
                            background: url("../../images/check-mark.svg") no-repeat;
                            width: 17px;
                            height: 13px;
                            display: inline-block;
                            flex: 0 0 17px;
                            margin: 6px 20px 0 0;
                        }
                    }
                }
                @include breakpoint-min(calc-rem(768)) {
                    .listing{
                        li{
                            font-size: calc-rem(16);
                        margin-bottom: 20px;
                            .mark{
                                width: 17px;
                                height: 13px;
                                display: inline-block;
                                flex: 0 0 17px;
                                margin: 6px 20px 0 0;
                            }

                        }
                    }
                }
            }
            .message-section {
                position: absolute;
                bottom: 20px;
                p {
                    margin: 0;
                    font-family: CircularStd-Book;
                    font-size: calc-rem(13);
                    color: rgba(255, 255, 255, 0.4);
                }
                @include breakpoint-min(calc-rem(992)) {
                    bottom: 30px;
                }
                @include breakpoint-min(calc-rem(1024)) {
                    bottom: 40px;
                }
            }
            @include breakpoint-min(calc-rem(992)) {
                margin-bottom: 0;
                height: 100%;
                padding: 30px;
            }
            @include breakpoint-min(calc-rem(1024)) {
                padding: 40px;
            }
        }
    }
}

.plan-services {
    background: #fbe5d4;
    padding: 60px 0 0 0;
    .container {
        max-width: 1130px;
        padding: 0 30px;
    }
    .tag {
        background: #fcc64e;
        border-radius: 5px;
        font-family: CircularStd-Book;
        display: inline-block;
        font-size: calc-rem(14);
        margin-bottom: 10px;
        color: #000000;
        padding: 5px 10px;
        line-height: normal;
    }
    .title {
        font-family: CircularStd-Bold;
        font-size: calc-rem(30);
        color: #000000;
        margin-bottom: 10px;
    }
    .tag-line {
        font-family: CircularStd-Book;
        font-size: calc-rem(14);
        color: #2c2c2c;
        margin-bottom: 51px;
    }
    .img-box{
        margin-bottom: 30px;
    }
    .listing {
        list-style: none;
        padding: 0;
        margin: 0 0 30px 0;
        li {
            font-family: CircularStd-Book;
            font-size: calc-rem(14);
            color: #0c192d;
            line-height: normal;
            margin-bottom: 20px;
            display: flex;
            align-items: flex-start;
            &:last-child{
                margin-bottom: 0;
            }
            .mark {
                background: url("../../images/check-mark.svg") no-repeat;
                width: 17px;
                height: 13px;
                display: inline-block;
                flex: 0 0 17px;
                margin: 3px 10px 0 0;
                background-position: -34px 0;
            }
        }
       
        
    }
    @include breakpoint-min(calc-rem(768)) {
        .tag{
            font-size: calc-rem(16);
        }
        .title{
            font-size: calc-rem(40);
        }
        .tag-line{
            font-size: calc-rem(20);
        }
        .img-box{
        margin-bottom: 0;
    }
    .listing{
        margin-bottom: 0;
        li{
            font-size: calc-rem(20);
            margin-bottom: 35px;
            .mark{
                margin: 8px 20px 0 0;
            }
        }
    }
    }
    @include breakpoint-min(calc-rem(992)) {
        .container {
            max-width: 1130px;
            padding: 0 80px;
        }
        .listing{
            padding: 0px 0 0 50px;
        }
        
    }
}

/* animation */
.head-section{
   > * { position: relative;
    transition: .5s;
    top: 80px;
    opacity: 0;
}
    &.active {

        > * {
            top: 0;
        opacity: 1;
    }
    > :nth-child(1) {
        transition-delay: 0.05s;
      }
      > :nth-child(2) {
        transition-delay: .3s;
      }
      }
     
}
.grid-item{
    position: relative;
    transition: .8s;
    top: 140px;
    opacity: 0;
    &.active{
        top: 0;
        opacity: 1;
    }
    &:nth-child(1) {
        transition-delay: 0.20s;
      }
      &:nth-child(2) {
        transition-delay: 0.50s;
      }
}
.plan-content{
    position: relative;
    transition: .5s;
    top: 100px;
    opacity: 0;
    &.active{
        top: 0;
        opacity: 1;
    }
}
.service-list{
    overflow: hidden;
    li { position: relative;
        transition: .5s;
        left: 100px;
        opacity: 0;
    
        &.active {
                left: 0;
            opacity: 1;
        
      
           :nth-child(n) {
            transition-delay: .3s;
          }
         
          }
        }
    }