* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
textarea,
select,
input,
button {
  &:focus {
    outline: none;
  }
}
img {
  max-width: 100%;
  max-height: 100%;
}
body {
  font-size: calc-rem(16);
  font-family: CircularStd-Medium;

  padding-top: 120px;
  @include breakpoint-min(calc-rem(768)) {
    padding-top: 180px;
   }
   @include breakpoint-min(calc-rem(992)) {
    padding-top: 180px;
   }
   @include breakpoint-min(calc-rem(1280)){
    // padding-top: 220px;
   }
  

}
.over-hide{
  overflow: hidden;
}
.full-container {
  max-width: 100%;
  padding: 0 30px;
  margin: 0 auto;
  @include breakpoint-min(calc-rem(992)) {
    padding: 0 80px;
  }
}
.container {
  max-width: 1280px;
  padding: 0 30px;
  @include breakpoint-min(calc-rem(992)) {
    padding: 0 80px;
  }
}
.bg-0 {
  background: #fff;
}
.bg-1 {
  background: #0e1f3b;
}
.bg-2 {
  background: #f7f7f7;
}
.bg-3 {
  background: #f3f3f3;
}
.bg-4 {
  background: #030404;
}
.bg-5 {
  background: #fbf8f2;
}
.bg-6 {
  background: #deeaf1;
}
.bg-7 {
  background: #f2f7fb;
}
.bg-8 {
  background: #c2e7f0;
}
.bg-9 {
  background: #0D1A2C;
}
.vertical-center {
  align-items: center;
}
.rupee{
  font-family: 'RupeeForadian';
  font-style: normal;
}
.home-banner {
  
}
.creator-banner {
  // background: url("../../images/creator-banner-xl.svg") no-repeat;
}
.about-banner {
  // background: url("../../images/about-banner-xl.svg") no-repeat;
}

.content-box {
  padding: 80px 0;
  overflow: hidden;
  @include breakpoint-min(calc-rem(768)) {
    padding: 120px 0;
  }
}
.box-banner{
  height: 100%;width:100%;
  
}
.reverse {
  .box-banner {
    text-align: right;
  }
  @include breakpoint-min(calc-rem(320)) {
    flex-direction: column-reverse;
    .box-banner {
      text-align: center;
    }
  }
  @include breakpoint-min(calc-rem(768)) {
    flex-direction: row;
    .box-banner {
      text-align: right;
    }
  }
}
.txt-black {
  color: #000 !important;
}
.center-align{
  text-align: center !important;
}
.text-r{
  .box-txt{
  margin-left: 0px;
  }
  .box-banner{
    text-align:center;
  }
}
.text-l{
  .box-txt{
  margin-right:0px;
  }
  .box-banner{
    text-align:center;
  }
}
@include breakpoint-min(calc-rem(768)) {
  .text-r{
    .box-txt{
    margin-left: 90px;
    }
    .box-banner{
      text-align: left;
    }
  }
  .text-l{
    .box-txt{
    margin-right: 90px;
    }
    .box-banner{
      text-align: right;
    }
  }
}
.m-r-0{
  margin-right:-15px; 
  @include breakpoint-min(calc-rem(768)) {
    margin-right:0; 
  }
}
.pd-0{
  padding:0;
}
.mb-40{
  margin-bottom: 40px !important;
}
