.banner-bx {
  min-height: calc(100vh - 90px);

  position: relative;
  @include breakpoint-min(calc-rem(320)) {
    background-size: 100%;
    // &:after {
    //   content: "";
    //   height: 50%;
    //   width: 100%;
    //   position: absolute;
    //   bottom: 20%;
    //   z-index: 0;
    //   background-image: linear-gradient(white, white, rgba(255, 255, 255, 0));
    // }
  }
  // @include breakpoint-min(calc-rem(576)) {
  //   background-size: 160%;
  //   &:after {
  //     content: "";
  //     height: 50%;
  //     width: 100%;
  //     position: absolute;
  //     top: 0%;
  //     z-index: 0;
  //     background-image: linear-gradient(white, white, rgba(255, 255, 255, 0));
  //   }
  // }
  @include breakpoint-min(calc-rem(320)) {
    background-size: 160%;
    background-position: bottom right;
    @media only screen and (orientation: landscape) {
      background-size: 80%;
      background-position: bottom right;
    }
  }
  @include breakpoint-min(calc-rem(768)) {
    min-height: calc(100vh - 180px);
    background-size: 190%;
    background-position-y: 100%;
    background-position-x: 80%;
  }
  @include breakpoint-min(calc-rem(992)) {
    background-size: 130%;
    background-position-y: 100%;
    background-position-x: 80%;
  }
  @include breakpoint-min(calc-rem(1025)) {
    background-size: cover;
    background-position: bottom right;
  }
  @include breakpoint-min(calc-rem(1280)) {
    min-height: calc(100vh - 220px);
  }
  @media only screen and (min-width : 1024px) and (max-width : 1366px) and (orientation : portrait) {
    padding-bottom: 100px;
    min-height:auto;
  }


}
.home-banner {
  overflow: hidden;
  .full-container{
    padding:0 30px; 
  }
  @include breakpoint-min(calc-rem(992)) {
    .full-container{
      padding:0 0 0 80px; 
    }
  }
}
.banner-txt {
  padding: 0 15px;
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
  .title {
    font-family: "CircularStd-Medium";
    font-size: calc-rem(50);
    color: #000000;
    line-height: 50px;
    margin: 0 0 20px 0;
    span {
      color: #2362be;
    }
  }
  p {
    font-family: "Avenir-Book";
    font-size: calc-rem(16);
    color: #000;
    margin: 0 50px 20px 0;
  }
  a {
    font-family: "CircularStd-Book";
    background: #0f6cf3;
    border-radius: 5px;
    padding: 14px 53px;
    font-size: calc-rem(14);
    color: #ffffff;
    display: inline-block;
    margin-right: 10px;
  }

  @include breakpoint-min(calc-rem(320)) {
    width: 100%;
    text-align: center;
    .h2 {
      font-size: calc-rem(35);
      line-height: normal;
      width: 300px;
      text-align: center;
      margin: 0 auto 20px;
    }
    p {
      margin: 0 auto 18px;
      font-size: calc-rem(16);
      width: 300px;
    }
    // @media only screen and (orientation: landscape) {
    //   width: 75%;
    //   text-align: left;
    // }
  }
  @include breakpoint-min(calc-rem(768)) {
    margin-bottom: 30px;
    h2 {
      font-size: calc-rem(30);
      line-height: 55px;
      width: auto;
      text-align: center;
      margin: 0 auto 20px;
    }
    p {
      font-size: calc-rem(17);
      margin: 0 0px 30px 0;
      width: auto;
      text-align: center;
    }
    a {
      font-size: calc-rem(16);
      padding: 10px 30px;
    }
  }
  @include breakpoint-min(calc-rem(992)) {
    margin-bottom: 0;
    margin-top: 10%;
    text-align: left;
    h2 {
      font-size: calc-rem(40);
      text-align: left;
      margin: 0 auto 20px;
    }
    p{
      text-align: left;
      margin: 0 50px 30px 0;
    }
  }
  @include breakpoint-min(calc-rem(1280)) {
    max-width: 600px;
    h2 {
      font-size: calc-rem(50);
    }
    p {
      margin: 0 50px 40px 0;
    }
  }
  @include breakpoint-min(calc-rem(1281)) {
    h2 {
      font-size: calc-rem(60);
      line-height: 70px;
      margin-bottom: 40px;
    }
    p {
      font-family: "Avenir-Medium";
      font-size: calc-rem(22);
    }
  }
  .year-box {
    margin-top: 90px;
    h4 {
      font-family: CircularStd-Medium;
      font-size: calc-rem(25);
      color: #080808;
      margin: 0;
    }
    p {
      font-family: CircularStd-Book;
      font-size: calc-rem(18);
      color: #878787;
      margin-bottom: 10px;
    }
    @include breakpoint-min(calc-rem(320)) {
      @media only screen and (orientation: landscape) {
        margin-top: 40px;
      }
    }
    @include breakpoint-min(calc-rem(768)) {
      @media only screen and (orientation: landscape) {
        margin-top: 90px;
      }
    }
  }
}
.banner-box {
    margin-bottom: 80px;
  @include breakpoint-min(calc-rem(768)) {
    padding-left: 15px;
    margin-bottom: 0px;
  }
  @include breakpoint-min(calc-rem(992)) {
    padding-left: 0;
  }
  position: relative;
  // overflow: hidden;
}
.home-animation {
  position: relative;
  .grid-1,.grid-2,.grid-3,.grid-4 {
    padding: 0 10px; 
  }

  @mixin box {
    .box {
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .grid-2 {
    padding-top: 10%;
    @include box();
    img{
      width: 100%;
    }
    .box{
      .brands{
        .item{
          border: 3px solid #eac5aa; 
          img{
            width: 80%;margin: 0 auto;display: block;
          }
          @include breakpoint-min(calc-rem(640)) {
            border: 5px solid #eac5aa; 
          }
          @include breakpoint-min(calc-rem(992)) {
            border: 5px solid #eac5aa; 
          }
          @include breakpoint-min(calc-rem(1024)) {
            border: 8px solid #eac5aa; 
          }
        }
      }
    }

  }
  .grid-3 {
    padding-top: 5%;
    @include box();
    .box:first-child {
      width: 70%;
      margin: 0 auto 30px;
      img{
        width: 100%;
      }
    }
  }
  .grid-4 {
    padding-top: 14%;
    .box {
      img {
        max-width: inherit;
        max-height: inherit;
        height: 50px;
        @include breakpoint-min(calc-rem(768)) {
          height: 110px;
        }
      }
    }

  }
  .line{
    position: absolute;
    top:0;
    right: -25%;
    z-index: -1;
  }
}
.spacer {
  padding: 120px 0;
}
.boxes {
  display: flex;
  flex-wrap: wrap;
}
.box-group {
  .main-title {
    font-family: CircularStd-Medium;
    color: #262626;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 60px;
    @include breakpoint-min(calc-rem(320)) {
      font-size: calc-rem(25);
      line-height: normal;
    }
    @include breakpoint-min(calc-rem(768)) {
      font-size: calc-rem(40);
      line-height: 50px;
    }
  }
  .box {
    border-radius: 10px;
    padding: 25px;
    position: relative;
    margin-bottom: 30px;
    height: calc(100% - 30px);
    @include breakpoint-min(calc-rem(320)) {
      padding: 15px;
    }
    @include breakpoint-min(calc-rem(992)) {
      padding: 25px;
    }
    .title {
      font-family: "CircularStd-Medium";
      font-size: calc-rem(25);
      color: #303030;
      padding: 40px 80px 0 0;
      margin: 0 0 20px 0;
      @include breakpoint-min(calc-rem(320)) {
        font-size: calc-rem(20);
      }
      @include breakpoint-min(calc-rem(992)) {
        font-size: calc-rem(25);
      }
    }
    .txt {
      opacity: 0.5;
      font-family: Avenir-Book;
      font-size: calc-rem(17);
      color: #000000;
      margin: 0;
      @include breakpoint-min(calc-rem(320)) {
        font-size: calc-rem(15);
      }
      @include breakpoint-min(calc-rem(992)) {
        font-size: calc-rem(17);
      }
    }
    .icon {
      background: #fafafa;
      height: 70px;
      width: 70px;
      position: absolute;
      right: 20px;
      display: flex;
      align-items: center;
      img {
        max-height: 100%;
        max-width: 100%;
      }
    }
  }
}

.box-txt {
  font-family: CircularStd-Medium;
  .xs-title {
    font-size: calc-rem(20);
    color: #000000;
    margin-bottom: 20px;
  }
  .lg-title {
    color: #262626;
    line-height:normal;
    margin-bottom: 40px;
  }
  .text-box {
    opacity: 0.9;
    font-size: calc-rem(16);
    color: #262626;
    font-family: Avenir-Book;
    width: 300px;
    margin:  30px auto 30px;
  }
  &.influencer-textbox {
    margin-left: 0px;
    .text-box {
      opacity: 0.5;
      font-family: Avenir-Medium;
      margin-bottom: 30px;
      &:last-child {
        margin: 0;
      }
    }
  }
  @include breakpoint-min(calc-rem(320)) {
    text-align: center;
    .lg-title {
      font-size: calc-rem(30);
      line-height: 40px;
      width: 300px;
      margin: 0 auto;
    }
  }
  @include breakpoint-min(calc-rem(768)) {
    text-align: left;
    .lg-title {
      font-size: calc-rem(25);
      line-height: 45px;
      width: auto;
      margin:  auto;
    }
    .text-box {
      margin-bottom: 0;
      font-size: calc-rem(18);
      width: auto;

    }
  }
  @include breakpoint-min(calc-rem(1024)) {
    text-align: left;
    .lg-title {
      font-size: calc-rem(30);
      line-height: 50px;
    }
  }
  @include breakpoint-min(calc-rem(1200)) {
    text-align: left;
    .lg-title {
      font-size: calc-rem(40);
      line-height: 55px;
    }
    &.influencer-textbox {
      margin-left: 60px;
    }
  }
}
.dashboard-box {
  padding-bottom: 0;
  .tab-content {
    position: relative;
    &:after {
      content: "";
      bottom: 0;
      width: 100%;
      height: 50%;
      left: 0;
      background-image: linear-gradient(rgba(0, 0, 0, 0), #0d1a2c);
      position: absolute;
    }
  }
  .controler {
    margin-top: 30px;
    ul {
      display: inline-block;
      li {
        position: relative;
        padding-left: 35px;
        margin-bottom: 10px;
        margin-right:10px;
        text-align: left;
        &:before {
       //   content: "";
          position: absolute;
          left: 0;
          top: 10px;
          width: 20px;
          height: 20px;
          background: #2a3c55;
        }
        &:nth-child(1){
          background: url(../../images/ico1.svg) no-repeat;
          background-position:0 10px; 
        }
        &:nth-child(2){
          background: url(../../images/ico2.svg) no-repeat;
          background-position:0 10px; 
        }
        &:nth-child(3){
          background: url(../../images/ico3.svg) no-repeat;
          background-position:0 10px; 
        }
        @include breakpoint-min(calc-rem(768)){
          padding-left: 40px;
          &:before {
            top: 10px;
            width: 25px;
            height: 25px;
          }
        }
      }
      a {
        opacity: 0.45;
        font-size: calc-rem(14);
        color: #ffffff;
        padding: 10px 0;
        transition: .8s;
        &.active {
          opacity: 1;
        }
        @include breakpoint-min(calc-rem(768)){
          font-size: calc-rem(18);
        }
      }
    }
  }
}
.influencers-box{
  position: relative;
  .box{
    position: absolute;
    
    border-radius: 100%;
    overflow: hidden;
    img{
      
    }
  }
  .box-1{
    top:50%;
    left: 50%;
    width: 30%;
    height: 30%;
    margin-top:-15%;
    margin-left:-15%;  
    //transform: translate(-50%, -50%);
    background: #FBC02D;
  }
  .box-2{
    bottom: 25%;
    left: 7%;
    width: 20%;
    height: 20%;
    // margin-bottom:-10%;
    // margin-left:-10%;
    //transform: translate(-50%, -50%);
    background: #F27774;
  }
  .box-3{
    top:19%;
    left: 11%;
    width: 15%;
    height: 15%;
    // margin-top:-7.5%;
    // margin-left:-7.5%;
    //transform: translate(-50%, -50%);
    background: #E9C6AB;
  }
  .box-4{
    top:12%;
    right: 20%;
    width: 12%;
    height: 12%;
    // margin-top:-5.5%;
    // margin-right:-5.5%;
    //transform: translate(-50%, -50%);
    background: #E1E1E8;
  }
  .box-5{
    top:42%;
    right: 3%;
    width: 11%;
    height: 11%;
    // margin-top:-5.5%;
    // margin-right:-5.5%;
    //transform: translate(-50%, -50%);
    background: #A2C4DE;
  }
  .box-6{
    bottom:15%;
    right: 33%;
    width: 11%;
    height: 11%;
    // margin-bottom:-5.5%;
    // margin-right:-5.5%;
    //transform: translate(-50%, -50%);
    background: #8692A2;
  }

}
.content-box.dark-box {
  .xs-title,
  .lg-title,
  .text-box {
    color: #fff;
  }
}
.clients-box {
  padding: 50px 0;
  .title {
    font-family: CircularStd-Book;
    font-size: calc-rem(20);
    color: #878787;
    margin-bottom: 45px;
    text-align: center;
    flex: 0 0 100%;
  }
  .logo-box {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-height: 100%;
      max-width: 100%;
      transition: .5s;
      
    }
    @include breakpoint-min(calc-rem(992)){
      
        img{
          // filter: grayscale(1);
        }
        &:hover img{
          // filter: grayscale(0);
        }
      
    }
  }
}
.test-container {
  padding-bottom: 51px;
  position: relative;
  // &:after {
  //   content: "";
  //   position: absolute;
  //   height: 141px;
  //   width: 100%;
  //   bottom: 0;
  //   left: 0;
  //   background-image: linear-gradient(rgba(255, 255, 255, 0), #fbf8f2, #fbf8f2);
  // }
}
.test-box {
  margin-top: 20px;
  padding-top: 20px;
  .box-grid {
    margin: 0 auto;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      height: 141px;
      width: 100%;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(rgba(255, 255, 255, 0), #fbf8f2, #fbf8f2);
    }
    &:before {
      content: "";
      position: absolute;
      height: 121px;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 1;
      background-image: linear-gradient(#fbf8f2, #fbf8f2,rgba(255, 255, 255, 0));
    }
    .listing {
      background: #fff;
      border-radius: 10px;
      padding: 15px;
      display: flex !important;
      flex-wrap: wrap;
      margin-top: 36px;
    }
    .img-box {
      height: 70px;
      border-radius: 10px;
      overflow: hidden;
      margin: 0 0 10px 0;
      flex: 0 0 100%;
      img {
        margin: 0 auto;
      }
    }
    .text-box {
      font-family: CircularStd-Book;
      color: #000000;
      flex: 0 0 100%;
      p {
        font-size: calc-rem(14);
        margin-bottom: 10px;
      }
      span {
        opacity: 0.5;
        font-size: calc-rem(11);
      }
    }
    @include breakpoint-min(calc-rem(320)) {
      width: 100%;
    }
    @include breakpoint-min(calc-rem(768)) {
      width: 430px;

      .listing {
        background: #fff;
        border-radius: 10px;
        padding: 25px;
        display: flex !important;
        flex-wrap: wrap;
        margin-top: 36px;
      }
      .img-box {
        width: 100px;
        height: 100px;
        border-radius: 10px;
        overflow: hidden;
        margin-right: 25px;
        flex: 0 0 100px;
      }
      .text-box {
        font-family: CircularStd-Book;
        color: #000000;
        flex: 0 0 calc(100% - 125px);
        p {
          font-size: calc-rem(18);
          margin-bottom: 10px;
        }
        span {
          opacity: 0.5;
          font-size: calc-rem(13);
        }
      }
    }
  }
}

.info-box {
  margin: 50px auto;
  display: flex;
  align-items: center;
  padding: 20px 0;
  border-radius: 5px;
  flex-wrap: wrap;
  .info-content {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 50px;
  }
  .title {
    font-family: CircularStd-Medium;
    color: #ffffff;
    letter-spacing: 0;
    margin-bottom: 10px;
  }
  .txt {
    opacity: 0.8;
    font-family: Avenir-Medium;
    color: #ffffff;
    margin-bottom: 10px;
  }
  a.cta-btn {
    // background: #0f6cf3;
    border-radius: 5px;
    // padding: 10px 30px;
    text-decoration: underline;
    font-family: CircularStd-Book;
    font-size: calc-rem(16);
    color: #ffffff;
    display: inline-block;
    margin-bottom: 40px;
    opacity: 0.8;
    transition: .5s;
    &:hover{
      opacity: 1;
    }
  }
  .img-btns{
    a{
      margin-right: 10px;
    margin-bottom: 10px;
    }
  }
  .img-box {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 20px;
    text-align: center;
    margin-bottom: -20px;
  }
  @include breakpoint-min(calc-rem(320)) {
    .info-content {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0 30px;
    }
    .title {
      font-size: calc-rem(30);
    }
    .txt {
      font-size: calc-rem(15);
    }
    .img-box {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  @include breakpoint-min(calc-rem(768)) {
    .info-content {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 20px;
    }
    .title {
      font-size: calc-rem(25);
    }
    .txt {
      font-size: calc-rem(15);
    }
    
    .img-box {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  @include breakpoint-min(calc-rem(992)) {
    .info-content {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 50px;
    }
    .title {
      font-size: calc-rem(30);
      margin-bottom: 20px;
    }
    .txt {
      font-size: calc-rem(16);
    }
    .img-box {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  @include breakpoint-min(calc-rem(1280)) {
   
    .title {
      font-size: calc-rem(40);
      margin-bottom: 30px;
    }
    .txt {
      font-size: calc-rem(17);
    }
  }
}
.img-btns {
  a, img {
    background: none;
    padding: 0;
    display: inline-block;
    width: 110px;
    cursor: pointer;
    
  }
  img {
   // width: 100%;
  }
  @include breakpoint-min(calc-rem(480)) {
    a {
      width: auto;
    }
  }
}
// .gallery{
//  position: relative;
//   .img-box {
//     margin: 0 10px;
//     padding: 0;
//     position: absolute;
//     list-style-type: none;
//     height: 300px;
//     img{
//       height: 100%;
//       object-fit: cover;
//       max-width: inherit;
//       max-height: inherit;
//     }
// }
// }
.scroll1,
.scroll2,
.scroll3,
.scroll4,
.scroll5,
.scroll6 {
  display: none;
}
.scroll1 li,
.scroll2 li,
.scroll3 li,
.scroll4 li,
.scroll5 li {
  height: 200px;
  margin: 0 10px;
}
.scroll1 img,
.scroll2 img,
.scroll3 img,
.scroll4 img,
.scroll5 img,
.scroll6 img {
  height: 100%;
}
.slider-grid-box {
  padding: 0 30px;
  @include breakpoint-min(calc-rem(992)) {
    padding: 0 30px 0 0;
  }
}
.infiniteslide_wrap {
  position: relative;
}
.slider-grid-box .box-txt {
  margin-left: 0px;
}
.slider-box {
  position: relative;
  margin: 0 -30px;
  z-index: 0;

  @include breakpoint-min(calc-rem(768)) {
    margin: 0 -20% 0 0;
    &:after {
      content: "";
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0), #0d1a2c);
      position: absolute;
      right: -3px;
      height: 100%;
      width: 25%;
      top: -1px;
      z-index: 1;
    }
  }

  .circle {
    // content: "";
    position: absolute;
    background: url(../../images/circle.svg) no-repeat;
    background-size: 100%;
    height: 52px;
    width: 55px;
    top: 0%;
    transform: translate(0%, -30px);
    z-index: 1;
    left: 20%;
    @include breakpoint-min(calc-rem(768)) {
      height: 70px;
      width: 75px;
    }
  }
  .line-arrow {
    //  content: "";
    position: absolute;
    background: url(../../images/line-arrow.svg) no-repeat;
    background-size: 100%;
    height: 75px;
    width: 95px;
    bottom: 0;
    transform: translate(0%, 30px);
    z-index: 1;
    right: 40%;
    @include breakpoint-min(calc-rem(768)) {
      height: 120px;
      width: 155px;
    }
  }
}
